// src/components/IntroductionGuide.js
import React from 'react';
import { Carousel, Button, ProgressBar, Container, Row, Col } from 'react-bootstrap';
import {
	FaRocket,
	FaKey,
	FaInfoCircle,
	FaCogs,
	FaGavel,
	FaFileAlt,
	FaCheckCircle,
} from 'react-icons/fa';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import rehypeSanitize from 'rehype-sanitize';

const IntroductionGuide = ({ onFinish, onSkip }) => { // Adicionamos a prop onSkip
	const [index, setIndex] = React.useState(0);

	const handleSelect = (selectedIndex) => {
		setIndex(selectedIndex);
	};

	const slides = [
		{
			title: 'Configuration form',
			icon: <FaRocket size={60} color="#007bff" />,
			description: `
**Welcome to ene3xt.ai!** Welcome! Genhex4j is designed to make it easier to create Java applications in a simple and intuitive way. We will guide you step by step to ensure that you make the most of all the features offered.
			`,
			buttonText: 'Next',
			buttonVariant: 'primary',
			onClick: () => setIndex(index + 1),
		},
		{
			title: 'API Credentials',
			icon: <FaKey size={60} color="#28a745" />,
			description: `
			To get started, you'll need to enter your API Key from an OpenAI-compatible language model. This key is essential for authentication and for us to access the resources needed to build your custom Java app.
			
### How to Get an API Key:
1. Go to [https://console.groq.com/playground](https://console.groq.com/playground).
2. Create an account or log in if you already have one.
3. Navigate to the API Keys section and generate a new key..
4. Save the generated key; you will use it in the next step of the form..
			`,
			buttonText: 'Next',
			buttonVariant: 'primary',
			onClick: () => setIndex(index + 1),
		},
		{
			title: 'General Information',
			icon: <FaInfoCircle size={60} color="#17a2b8" />,
			description: `
			Now, let's fill in the basic details of your project. This information is essential for the correct organization and structuring of your API.

### What to Fill in:
- **Package Name: Set the name of the package where your API will be located.
- **Entity Name: Name of the main entity that your API will manage.
- **System Prompt: Provide a brief prompt that will guide the virtual assistant's behavior.
			`,
			buttonText: 'Next',
			buttonVariant: 'primary',
			onClick: () => setIndex(index + 1),
		},
		{
			title: 'JPA Attributes',
			icon: <FaCogs size={60} color="#ffc107" />,
			description: `
			Define the attributes of your JPA entity, including data types, primary keys, and other essential properties. These attributes determine how your API will interact with the database.

### How to Define Attributes:
- **Attribute Name:** The identifying name of the field.
- **Attribute Type:** The data type (e.g., String, Integer, Date).
- **Primary Key:** Indicate whether the attribute is a primary key.
- **Required:** Define whether the field is required.
- **Maximum Length:** Specify the maximum length allowed.
- **Generated Value:** Indicate whether the value is automatically generated.
- **Column Definition:** Provide additional definitions for the column, if necessary.
			`,
			buttonText: 'Next',
			buttonVariant: 'primary',
			onClick: () => setIndex(index + 1),
		},
		{
			title: 'Business Rules',
			icon: <FaGavel size={60} color="#dc3545" />,
			description: `
Add and manage business rules that will guide the behavior of your API according to the specific needs of your project. Well-defined rules ensure that your API functions correctly and meets the desired requirements.

### How to Add Rules:
- **Rule Name:** Identify the rule.
- **Description:** Describe the purpose of the rule.
- **Rule Input:** Define what the rule expects to receive.
- **Rule Output:** Specify the expected result.
- **LLM Generated Logic:** Detail the logic that will be implemented.
- **Java Functional Interface:** Define the corresponding functional interface.
- **Interface Method Name:** Name the method that will execute the rule.
			`,
			buttonText: 'Next',
			buttonVariant: 'primary',
			onClick: () => setIndex(index + 1),
		},
		{
			title: 'Custom Templates',
			icon: <FaFileAlt size={60} color="#6f42c1" />,
			description: `
Customize the templates used by your API to reflect your project's identity and specific requirements. Custom templates increase development efficiency and quality, ensuring that your API meets your exact needs.

### How to Customize Templates:
- **Template Name: Give the template a meaningful name.
- **Template Content: Enter the content that defines the template's structure and functionality.
			`,
			buttonText: 'Next',
			buttonVariant: 'primary',
			onClick: () => setIndex(index + 1),
		},
		{
			title: 'Review and Submission',
			icon: <FaCheckCircle size={60} color="#28a745" />,
			description: `
Review all information you've entered to ensure it's correct before submitting your form. A careful review will prevent errors and ensure your API is built as expected.

### What to Review:
- **API Credentials:** Verify that the API Key is correct.
- **General Information:** Confirm the package and entity details.
- **JPA Attributes:** Review the defined attributes.
- **Business Rules:** Ensure that all rules are configured correctly.
- **Templates: Verify that custom templates are appropriate.
			`,
			buttonText: 'Start Form',
			buttonVariant: 'success',
			onClick: onFinish, // Chama a função de finalizar introdução
		},
	];

	return (
		<Container fluid className="p-5 bg-white rounded shadow">
			<Row className="justify-content-center">
				<Col md={8}>
					<ProgressBar now={((index + 1) / slides.length) * 100} className="mb-4" />
					<Carousel activeIndex={index} onSelect={handleSelect} indicators={false} controls={false} interval={null}>
						{slides.map((slide, idx) => (
							<Carousel.Item key={idx}>
								<div className="d-flex flex-column align-items-center text-center" style={{ minHeight: '60vh' }}>
									<div className="mb-4">{slide.icon}</div>
									<h2>{slide.title}</h2>
									<div className="mt-3" style={{ fontSize: '1.1rem', maxWidth: '600px' }}>
										<ReactMarkdown
											children={slide.description}
											rehypePlugins={[rehypeRaw, rehypeSanitize]}
											components={{
												a: ({ node, ...props }) => <a {...props} target="_blank" rel="noopener noreferrer" />,
												ul: ({ node, ...props }) => <ul {...props} className="list-unstyled list-disc pl-4" />,
												ol: ({ node, ...props }) => <ol {...props} className="list-decimal pl-4" />,
												h2: ({ node, ...props }) => <h2 {...props} />,
												h3: ({ node, ...props }) => <h3 {...props} />,
												p: ({ node, ...props }) => <p {...props} />,
												strong: ({ node, ...props }) => <strong {...props} />,
												em: ({ node, ...props }) => <em {...props} />,
											}}
										/>
									</div>
									<Button
										variant={slide.buttonVariant}
										onClick={() => {
											console.log(`Slide ${idx + 1}: Botão "${slide.buttonText}" clicado`);
											slide.onClick();
										}}
										className="mt-4 px-4 py-2"
									>
										{slide.buttonText}
									</Button>
								</div>
							</Carousel.Item>
						))}
					</Carousel>
					<div className="d-flex justify-content-between mt-4">
						{index > 0 && (
							<Button variant="outline-secondary" onClick={() => setIndex(index - 1)}>
								To Go Back
							</Button>
						)}
						{/* Botão "Pular Introdução" */}
						<Button variant="link" onClick={onSkip} className="text-decoration-none">
							Skip Introduction
						</Button>
					</div>
				</Col>
			</Row>
		</Container>
	);
};

export default IntroductionGuide;
