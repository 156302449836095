import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import ApiForm from './ApiForm';

function App() {
  return (
    <div className="App container mt-4">
      <h1 className="mb-3">
        ene3xt.ai <small className="text-muted">:: java code agent</small>
      </h1>

      <div className="mb-4">
        <a
          href="https://github.com/JimSP/ene3xt.ai"
          target="_blank"
          rel="noopener noreferrer"
          className="btn btn-outline-dark btn-sm"
        >
          ⭐ View on GitHub
        </a>
      </div>

      <ApiForm />
    </div>
  );
}

export default App;
